<template>
	<div class="project">
		<div class="main">
			<div class="content">
				<div class="title">
					<img src="@/assets/ques/ques.png"> 服务条款
				</div>
				<div class="law">
					<h3>一、接受条款</h3>
					<p>欢迎光临卡萨帝官网（以下简称“本网站”）。本网站根据以下服务条款为您提供服务。这些条款可由本网站随时更新，且毋须另行通知。本网站服务条款（以下简称“本服务条款”）一旦发生变动, 本网站将在网页上公布修改内容。修改后的服务条款一旦在网页上公布即有效代替原来的服务条款。您可随时造访本网站查阅最新版服务条款。此外，当您使用本网站特殊服务时，您和本网站应遵守本网站随时公布的与该服务相关的指引和规则。前述所有的指引和规则，均构成本服务条款的一部分。您在使用相关服务时,应关注并遵守其所适用的相关条款。
						您在使用本网站提供的各项服务之前，应仔细阅读本服务条款。如您不同意本服务条款及随时对其的修改，您可以主动取消本网站提供的服务；您一旦使用本网站服务，即视为您已了解并完全同意本服务条款各项内容，包括本网站对服务条款随时所做的任何修改，并成为本网站用户（以下简称“用户”） 。</p>
					<h3>二、服务说明</h3>
					<p>本网站目前经由其产品组合，向用户提供存取丰富的网上资源的诸多服务，包括各种信息工具、网上论坛、个人化内容等（以下简称“本服务”）。除非本服务条款另有其它明示规定，增加或强化目前本服务的任何新功能，包括所推出的新产品，均受到本服务条款之规范。您了解并同意，本服务仅依其当前所呈现的状况提供，对于任何用户通讯或个人化设定之时效、删除、传递错误、未予储存或其它任何问题，本网站均不承担任何责任。本网站保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。为使用本服务，您必须能够自行经有法律资格对您提供互联网接入服务的第三方，进入国际互联网，并应自行支付相关服务费用。此外，您必须自行配备及负责与国际联网连线所需之一切必要装备，包括计算机、数据机或其它存取装置。</p>
					<h3>三、遵守法律</h3>
					<p>您同意遵守《中华人民共和国合同法》、《中华人民共和国著作权法》及其实施条例、《全国人民代表大会常务委员会关于维护互联网安全的决定》（“人大安全决定”）、《中华人民共和国保守国家秘密法》、《中华人民共和国电信条例》（“电信条例” ）、《中华人民共和国计算机信息系统安全保护条例》、《中华人民共和国计算机信息网络国际联网管理暂行规定》及其实施办法、《计算机信息系统国际联网保密管理规定》、《互联网信息服务管理办法》、《计算机信息网络国际联网安全保护管理办法》、《互联网电子公告服务管理规定》（“电子公告规定”）等相关中国法律法规的任何及所有的规定，并对以任何方式使用您的密码和您的帐号使用本服务的任何行为及其结果承担全部责任。如违反《人大安全决定》有可能构成犯罪，被追究刑事责任。《电子公告规定》则有明文规定，上网用户使用电子公告服务系统对所发布的信息负责。《电信条例》也强调，使用电信网络传输信息的内容及其后果由电信用户负责。在任何情况下，如果本网站有理由认为您的任何行为，包括但不限于您的任何言论和其它行为违反或可能违反上述法律和法规的任何规定，本网站可在任何时候不经任何事先通知终止向您提供服务。</p>
					<h3>四、您的注册义务</h3>
					<p>为了能使用本服务，您同意以下事项∶依本服务注册表之提示提供您本人真实、正确、最新及完整的资料（以下简称“登记资料”）；及随时更新登记资料，确保其为真实、正确、最新及完整的资料。若您提供任何错误、不实、过时或不完整或具误导性的资料；或者本网站有理由怀疑前述资料为错误、不实、过时或不完整或具误导性的，本网站有权暂停或终止您的帐号，并拒绝您于现在和未来使用本服务之全部或任何部分。本网站十分关心所有用户（特别是儿童）的安全及隐私。请记住，本服务设计之目的在于符合广大用户的需要。因此本服务及“内容”（如后述第六项所定义）是否适合于您的子女，您身为定监护人有责任加以判断。本网站无须对任何用户的任何登记资料承担任何责任，包括但不限于鉴别、核实任何登记资料的真实性、正确性、完整性、适用性及是否为最新资料的责任。</p>
					<h3>五、本网站隐私权政策</h3>
					<p>您提供的登记资料及本网站保留的有关您的若干其它资料将受到中国有关隐私的法律和本公司《隐私权政策》之规范。请访问本网站查阅本网站完整的隐私权政策。</p>
					<h3>六、用户帐号、密码及安全</h3>
					<p>完成本服务的登记程序之后，您获得到一个密码及帐号。您应对所有用您的密码及帐号的活动负完全的责任。当您的密码或帐号遭到未获授权的使用，或者发生其它任何安全问题时，应第一时间通知本网站。</p>
					<h3>七、提供者之责任</h3>
					<p>根据有关法律法规，本网站在此郑重提请您注意，任何经由本服务以上载、张贴、发送电子邮件或任何其它方式传送的资讯、资料、文字、软件、音乐、音讯、照片、图形、视讯、信息或其它资料（以下简称“内容”），无论系公开还是私下传送，均由内容提供者承担责任。本网站无法控制经由本服务传送之内容，因此不保证内容的正确性、完整性或品质。您已预知使用本服务时，可能会接触到令人不快、不适当或令人厌恶之内容。在任何情况下，本网站均不为任何内容负责，包含但不限于任何内容之任何错误或遗漏，以及经由本服务以张贴、发送电子邮件或其它方式传送任何内容而衍生之任何损失或损害。但本网站有权依法停止传输任何前述内容并采取相应行动，包括但不限于暂停用户使用本服务的全部或部分，保存有关记录，并向有关机关报告。</p>
					<h3>八、用户行为</h3>
					<p style="font-weight: 600;">
						用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为∶</br>
						1.上载、张贴、发送电子邮件或以其它方式传送含有下列内容之一的信息：
					</p>
					<p>
						# 反对宪法所确定的基本原则的；</br>
						
						# 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</br>
						
						# 损害国家荣誉和利益的；</br>
						
						# 煽动民族仇恨、民族歧视、破坏民族团结的；</br>
						
						# 破坏国家宗教政策，宣扬邪教和封建迷信的；</br>
						
						# 散布谣言，扰乱社会秩序，破坏社会稳定的；</br>
						
						# 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</br>
						
						# 侮辱或者诽谤他人，侵害他人合法权利的；</br>
						
						# 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</br>
						
						# 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</br>
					</p>
					<p style="font-weight: 600;"> 2. 以任何方式危害未成年人的利益；</br>
						3.冒充任何人或机构，包含但不限于本网站员工，或以虚伪不实的方式陈述或谎称与任何人或机构有关；</br>
						4.伪造标题或以其它方式操控识别资料，以伪造经由本服务传送之任何内容之来源；</br>
						5.将依据任何法律或合约或法定关系（例如由于雇佣关系和依据保密合约所得知或揭露之内部资料、专属及机密资料）知悉但无权传送之任何内容加以上载、张贴、发送电子邮件或以其它方式传送；</br>
						6.将侵害他人著作权、专利权、商标权、商业秘密、或其它专属权利（以下简称“专属权利”）之内容加以上载、张贴、发送电子邮件或以其它方式传送；</br>
						7.将任何广告信函、促销资料、“垃圾邮件”、“滥发信件"、“连锁信件”、“直销”或其它任何形式的劝诱资料加以上载、张贴、发送电子邮件或以其它方式传送，惟专供前述目的使用之区域（例如购物室），按照有关适用指引或规则进行的行为不在此限；</br>
						8.将设计目的在于干扰、破坏或限制任何计算机软件、硬件或通讯设备功能之计算机病毒（包括但不限于木马程序(Trojan horses)、蠕虫(worms)、定时炸弹、删除蝇(cancelbots)（以下简称“病毒”））或其它计算机代码、档案和程序之任何资料，加以上载、张贴、发送电子邮件或以其它方式传送；</br>
						9.破坏正常的对话流程、造成荧屏快速移动，或使本服务其它用户无法输入，或对其它用户参加即时交流的能力产生负面影响；</br>
						10.干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范；</br>
						11.跟踪或以其它方式骚扰他人；</br>
						12.故意或非故意地违反任何适用的当地、国家和国际法律；</br>
						13.未经合法授权而截获、篡改、收集、储存或删除他人个人信息、电子邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的。您已认可本网站未对本服务内容加以任何事先审查，对用户的使用行为也无法进行全面控制，您使用任何内容时，包括依赖前述内容之正确性、完整性或实用性时，您同意将自行加以判断并承担所有风险，而不依赖于本网站。但本网站及其指定人有权（但无义务）依其自行之考虑，拒绝和删除可经由本服务提供之违反本条款的或其它引起本网站反感的任何内容。您了解并同意，本网站依据法律法规的要求，或基于诚信为了以下目的或在合理必要范围内，认定必须将内容加以保存或揭露时，得加以保存或揭露：</p>
					<p> (a)遵守法律程序；</br>
						
						(b)执行本服务条款；</br>
						
						(c)回应任何第三人提出的权利主张；</br>
						
						(d)保护本网站、其用户及公众之权利、财产或个人安全；或</br>
						
						(e)其它本网站认为有必要的情况。</p>
					<p style="font-weight: 600;">您了解并同意经由本服务之技术处理及传送，您提供的任何内容，可能</p>
					<p>
						(a)经由各个网路加以传送，且</br>
 
                        (b)为了符合及配合连线网路或装置之技术要求而进行改变。
 
					</p>
					<h3>九、国际使用之特别警告</h3>
					<p>您已了解国际互联网的无国界性，同意遵守当地所有关于网上行为及内容之法律法规。您特别同意遵守有关从中国或您所在国家或地区输出信息之传输的所有适用法律法规。</p>
					<h3>十、在本网站张贴的公开信息</h3>
					<p style="font-weight: 600;">
						1.在本服务条款中，“本服务公开使用区域”系指一般公众可以使用的区域。</br>
                        2.您同意已就您于本服务公开使用区域及本服务其它任何公开使用区域张贴之内容，或包括照片、图形或影音资料等内容，授予本网站全球性、免许可费及非独家的使用权，本网站可以为了展示、散布及推广张贴前述内容之特定服务目的，将前述内容复制、修改、改写、改编或出版，对于照片及图形资料的上述使用，仅为张贴该照片或图形于本服务之目的而为之。在您将前述内容放入本服务期间，使用权持续有效；若您将前述内容自本服务中删除，则使用权于删除时终止。</br>
                        3.您同意已就您于本服务其它公开使用区域张贴的其它内容，授予本网站免许可费、永久有效、不可撤销、非独家及可完全再授权之权利在全球使用、复制、修改、改写、改编、发行、翻译、创造衍生性著作，及将前述内容（部分或全部）加以散布、表演、展示，及放入利用任何现在已知和未来开发出之形式、媒体和科技之其它著作物当中。
					</p>
					<h3>十一、 赔偿</h3>
					<p>由于您通过本服务提供、张贴或传送之内容、您与本服务连线、您违反本服务条款、或您侵害他人任何权利因而衍生或导致任何第三人提出任何索赔或请求，包括合理的律师费，您同意赔偿本网站及其子公司、关联企业、高级职员、代理人、品牌共有人或其它合作伙伴及员工，并使其免受损害。</p>
					<h3>十二、 禁止商业行为</h3>
					<p>您同意不对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。</p>
					<h3>十三、 关于使用及储存之一般措施</h3>
					<p>您承认关于本服务的使用本网站有权制订一般措施及限制，包含但不限于本服务将保留所张贴内容或其它上载内容之最长期间以及一定期间内您使用本服务之次数上限（及每次使用时间之上限）。通过本服务存储或传送之任何信息、通讯资料和其它内容，如被删除或未予储存，您同意本网站毋须承担任何责任。您亦同意，长时间未使用的帐号，本网站有权关闭。您也同意，本网站有权依其自行之考虑，不论通知与否，随时变更这些一般措施及限制。</p>
					<h3>十四、 服务之修改</h3>
					<p>本网站有权于任何时间暂时或永久修改或终止本服务（或其任何部分），而无论其通知与否。您同意对于本服务所作的任何修改、暂停或终止，本网站对您和任何第三人均无需承担任何责任。</p>
					<h3>十五、 终止服务</h3>
					<p>您同意本网站得基于其自行之考虑，因任何理由，包含但不限于缺乏使用，或本网站认为您已经违反本服务条款的文字及精神，终止您的密码、帐号或本服务之使用（或服务之任何部分），并将您在本服务内任何内容加以移除并删除。本网站亦得依其自行之考虑，于通知或未通知之情形下，随时终止本服务或其任何部分。您同意依本服务条款任何规定提供之本服务，无需进行事先通知即可中断或终止，您承认并同意，本网站可立即关闭或删除您的帐号及您帐号中所有相关信息及文件，及禁止继续使用前述文件或本服务。此外，您同意若本服务之使用被中断或终止或您的帐号及相关信息和文件被关闭或删除，本网站对您或任何第三人均不承担任何责任。</p>
					<h3>十六、 与广告商进行之交易</h3>
					<p>您通过本服务与广告商进行任何形式的通讯或商业往来，或参与促销活动，包含相关商品或服务之付款及交付，以及达成的其它任何相关条款、条件、保证或声明，完全为您与广告商之间之行为。有关法律法规有明文规定要求本网站承担责任以外，您因前述任何交易或前述广告商而遭受的任何性质的损失或损害，本网站均不予负责。</p>
					<h3>十七、 链接</h3>
					<p>本服务或第三人可提供与其它国际互联网上之网站或资源之链接。由于本网站无法控制这些网站及资源，您了解并同意，此类网站或资源是否可供利用，本网站不予负责，存在或源于此类网站或资源之任何内容、广告、产品或其它资料，本网站亦不予保证或负责。因使用或依赖任何此类网站或资源发布的或经由此类网站或资源获得的任何内容、商品或服务所产生的任何损害或损失，本网站不负任何直接或间接之责任。</p>
					<h3>十八、 本网站之专属权利</h3>
					<p>您了解并同意，本服务及本服务所使用之相关软件（以下简称“软件”）含有受到相关知识产权及其它法律保护之专有保密资料。您也了解并同意，经由本服务或广告商向您呈现之赞助广告或信息所包含之内容，亦受到著作权、商标权、服务商标、专利权或其它专属权利之法律保护。未经本网站或广告商明示授权，您不得修改、出租、出借、出售、散布本服务或软件之任何部份或全部，或据以制作衍生著作，或使用擅自修改后的软件，包括但不限于为了未经授权而使用本服务之目的。本网站仅授予您个人、不可移转及非专属之使用权，使您得于单机计算机使用其软件之目的码，但您不得（并不得允许任何第三人）复制、修改、创作衍生著作、进行还原工程、反向组译，或以其它方式发现原始码，或出售、转让、再授权或提供软件设定担保，或以其它方式移转软件之任何权利。您同意将通过由本网站所提供的界面而非任何其它途径使用本服务。</p>
					<h3>十九、 担保与保证</h3>
					<p style="font-weight: 600;"> 您明确了解并同意∶
						1.本服务条款的任何规定不会免除本网站对造成您人身伤害的、或因故意或重大过失造成您财产损失的任何责任。</br>
						2.您使用本服务之风险由您个人负担。本服务系依“现状”及“现有”基础提供。本网站对本服务不提供任何明示或默示的担保或保证，包含但不限于商业适售性、特定目的之适用性及未侵害他人权利等担保或保证。</br>
						3.本网站不保证以下事项∶
					</p>
					<p>
						(a)本服务将符合您的要求，</br>
						
						(b)本服务将不受干扰、及时提供、安全可靠或不会出错，</br>
						
						(c)使用本服务取得之结果正确可靠，</br>
						
						(d)您经由本服务购买或取得之任何产品、服务、资讯或其它信息将符合您的期望，且</br>
						
						(e)软件中任何错误都将得到更正。
					</p>
					<p style="font-weight: 600;">
						4.是否使用本服务下载或取得任何资料应由您自行考虑且自负风险，因任何资料之下载而导致的您电脑系统之任何损坏或数据流失等后果，由您自行承担。</br>
                        5.您自本网站或经由本服务取得的任何建议或信息，无论是书面或口头形式，除非本服务条款有明确规定，将不构成本服务条款以外之任何保证。
					</p>
					<h3>二十、 责任限制</h3>
					<p style="font-weight: 600;">
						您明确了解并同意，基于以下原因而造成的，包括但不限于利润、信誉、应用、数据损失或其它无形损失，本网站不承担任何直接、间接、附带、特别、衍生性或惩罚性赔偿责任（即使本网站事先已被告知发生此种赔偿之可能性亦然）：
					</p>
					<p>
						(a)本服务之使用或无法使用，</br>
 
                        (b)为替换从或通过本服务购买或取得之任何商品、数据、信息、服务，收到的讯息，或缔结之交易而发生的成本，</br>
 
                        (c)您的传输或数据遭到未获授权的存取或变造，</br>
 
                        (d) 任何第三方在本服务中所作之声明或行为，或</br>
 
                        (e)与本服务相关的其它事宜，但本服务条款有明确规定的除外。
					</p>
					<h3>二十一、 通知</h3>
					<p>向您发出的通知可经由站内消息、电子邮件、普通邮件和手机短信告之。本服务条款或其它事项有所变更时，本服务一般将向您显示此种通知或与该通知相关之链结。</p>
					<h3>二十二、 用户专属权利</h3>
					<p style="font-weight: 600;">
						本网站尊重他人知识产权，呼吁用户也要同样尊重知识产权。若您认为您的作品的著作权遭到侵害或您的知识产权被侵犯，请向本网站之著作权代理人提供以下资料∶</br>
                        1.著作权或其它知识产权所有人之有权代理人之电子或实体签名。</br>
                        2.对您主张遭到侵权之作品或知识产权进行的描述。</br>
                        3.对您主张遭到侵权之作品在网站上所处的位置进行的描述。</br>
                        4.您的地址、电话号码及电子邮件地址。
					</p>
					<h3>二十三、 一般条款</h3>
					<p style="font-weight:600">
						1.本服务条款构成您与本网站之全部协议，并规范您对于本服务之使用行为，并取代您先前与本网站所达成的全部协议。在您使用相关服务、使用第三方提供的内容或软件时，亦应遵从所适用之附加条款及条件。</br>
                        2.本服务条款及您与本网站之关系，均受到中华人民共和国法律管辖。</br>
                        3.本网站未行使或执行本服务条款任何权利或规定，不构成对前述权利或权利之放弃。</br>
                        4.倘本服务条款之任何规定因与中华人民共和国法律抵触而无效，您依然同意应依照法律，努力使该规定所反映之当事人意向具备效力，且本服务条款其它规定仍应具有完整的效力及效果。</br>
                        5.本服务条款之标题仅供方便而设，不具任何法律或契约效果。
					</p>
					<h3>二十四、 举报</h3>
					<p>倘发现任何违反本服务条款之情事，请通知本公司相关部门。</p>
				
 
					
			
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import DwFilter from '@/components/DwFilter.vue'
	import DwImg from '@/components/DwImg.vue'
	export default {
		name: 'Law',
		components: {
			DwFilter,
			DwImg
		},
		data() {
			return {
				
			}
		},
		methods: {
			
		}
	}
</script>
<style lang="less" scoped type="text/css">
	.project {
		position: relative;
		padding-top: 60px;
		.main {
			/* min-height: 100vh; */
			background: @second-color;
			padding: 10px 0 10px 0;
			margin: 0 auto;
			width: 100%;
			left: 0;
			top: 0;

			.content {
				width: 1200px;
				margin: 0 auto;
				background-color: #fff;
				padding: 60px;

				.title {
					font-size: 28px;
					color: @font-color;

					img {
						margin-right: 20px;
					}
				}
                .law{
					margin-top: 20px;
					p{
						font-size:13px;
                        color: rgba(159,172,172,1);
						line-height:30px;
					}
					h3{
						 
					}
				}
				
			}

			
		}
	}
</style>
<style lang="less" scoped type="text/css">
	@import url('@/mixin/base.less');
</style>